import { useDisclosure } from '@mantine/hooks';
import { Modal, Text } from '@mantine/core';
import './PromotionModal.css';

function PromotionModal({ title, message, courses, actions, image }) {
    const [opened, { open, close }] = useDisclosure(true);

    return (
        <>
            <Modal opened={opened}
                   onClose={close}
                   title={title}
                   centered size="xl"
                   classNames={{
                       title: "promotionModal__title",
                   }}
            >
                {message &&
                    <Text size="lg" weight={500} ta="center">{message}</Text>
                }

                {courses && courses.length > 0 &&
                    <div className="promotionModal__container">
                        {courses.map((course) => (
                            <Text size="sm">✅ {course}</Text>
                        ))}
                    </div>
                }

                {image &&
                    <img src={image} alt="Promotion" className="promotionModal__image"/>
                }

                <div className="promotionModal__container--row">
                    {actions}
                </div>
            </Modal>
        </>
    );
}

export default PromotionModal;